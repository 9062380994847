<template>
  <v-container
    class="products-list-page"
    fluid>
    <img-popup-preview
      v-model="previewEnabled"
      :img-src="currentImgPreview" />
    <v-row
      class="head-search">
      <v-col
        cols="6"
        sm="9"
        md="6"
        lg="auto"
        xl="auto">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="mapWarehouse"
          item-text="name"
          outlined
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          return-object
          solo />
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="6"
        lg="auto"
        xl="auto"
        :class="isMobile || $vuetify.breakpoint.md ? 'text-right' : ''"
      >
        <v-btn
          :disabled="disabledExportBtn"
          :loading="loading"
          outlined
          color="success"
          @click="confirmExport()">
          <v-icon>mdi-microsoft-excel</v-icon>
          Export
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="7"
        xl="6"
        class="col-search">
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          tags-width="300px"
          :is-mobile="isMobile"
          show-collection
          @on-search="searchProduct()" />
      </v-col>
    </v-row>

    <v-row
      v-for="(product, index) in products"
      :key="`product-${index}`"
      class="white elevation-3 my-8"
      :class="isMobile ? `mx-2` : ''">
      <v-col :cols="12">
        <product
          :product-id="product.id"
          :product-name="product.name"
          :model="product.model"
          :brand="product.brand"
          :variant="product.variant"
          :skus="product.skus"
          :categories="product.categories"
          :tags="product.tags"
          :photo-urls="product.photoUrls"
          :warehouse-id="warehouseSelected.id"
          :warehouse-name="warehouseSelected.name"
          :product-prototype="product.productPrototype || null"
          @addStock="addStock($event, index)"
          @deductStock="deductStock($event, index)"
          @preview:image="previewImg($event)" />
      </v-col>
    </v-row>

    <v-row
      v-if="isNotHaveProducts"
      justify="center"
      align="center">
      <v-col
        cols="auto"
        class="my-15">
        <p>There aren't any products. :( </p>
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-for="loader in loaders"
      :key="`loader-${loader}`"
      :loading="true"
      height="150"
      class="loader-product-list"
      type="list-item-avatar-three-line" />
    <v-overlay :value="loadingPage">
      <v-progress-circular
        indeterminate
        size="64" />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import ConvertQueryStringToArray from '@/assets/js/ConvertQueryStringToArray'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import ImgPopupPreview from '@/components/ImgModalPreview.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import Product from '../components/ProductInventoryBox.vue'

const productService = new ProductProvider()
const InventoryService = new InventoryProvider()

export default {
  name: 'ProductList',
  components: {
    product: Product,
    ImgPopupPreview,
    SearchByTagsAndBrand,
    WarehouseSelect
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      products: [],
      query: {
        page: 0,
        limit: 15,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        collection: ''
      },
      totalPage: 1,
      loading: false,
      getting: false,
      isLoadComplete: false,
      loadingTags: false,
      loadingPage: false,
      warehouse: 'main',
      warehouseSelected: {
        id: 0,
        name: 'Main',
        code: 'main'
      },
      totalProduct: 0
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      storeSetting: 'Store/storeSetting'
    }),
    isNotHaveProducts () {
      return this.products.length === 0 && this.isLoadComplete
    },
    loaders () {
      return this.isLoadComplete ? 0 : 2
    },
    disabledExportBtn () {
      return (this.warehouseSelected?.id === 0) || !this.products.length
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  watch: {
    warehouseSelected (val) {
      this.$router.push({
        name: 'ProductCostReport',
        query: {
          search: this.query.search,
          tags: this.query.tags,
          tagOperation: this.query.tagOperation,
          collection: this.query.collection,
          warehouse: val.code
        }
      })
    }
  },
  created () {
    this.initialInventory()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    previewImg (img) {
      this.currentImgPreview = img
      this.previewEnabled = !!this.currentImgPreview
    },
    initialInventory () {
      window.addEventListener('scroll', this.handleScroll)
      this.querySearch()
      this.fetchProducts()
    },
    querySearch () {
      const result = ConvertQueryStringToArray(this.$route.query)

      this.query.search = result.search ? result.search : ''
      this.query.brand = result.brand ? result.brand : 'all'
      this.query.tags = result.tags ? result.tags : []
      this.query.tagOperation = result.tagOperation ? result.tagOperation : 'OR'
      this.query.collection = result.collection ? result.collection : ''
      this.warehouse = result.warehouse ? result.warehouse : 'main'
      this.warehouseSelected = this.mapWarehouse.find((r) => r.code === this.warehouse)
    },
    async addStock ({ payload, skuIndex }, index) {
      try {
        this.loadingPage = true
        const createNote = await InventoryService.createDeliveryNotes({
          warehouse: this.warehouseSelected,
          ...payload
        })
        if (createNote.data.id) {
          // eslint-disable-next-line no-unused-vars
          const updateStatusNote = await InventoryService.updateStatusDeliveryNotes({
            id: createNote.data.id,
            status: 'approve'
          }, {
            warehouse: this.warehouseSelected,
            ...payload
          })
          await this.updateProductList(index, skuIndex)
          this.setSnackbar({
            value: true,
            message: 'เพิ่มสต็อกสำเร็จ',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('addStock', error)
        this.setSnackbar({
          value: true,
          message: `เพิ่มสต็อกไม่สำเร็จ -> ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loadingPage = false
      }
    },
    async deductStock ({ payload, skuIndex }, index) {
      try {
        this.loadingPage = true
        const createNote = await InventoryService.createWithdrawNotes({
          warehouse: this.warehouseSelected,
          ...payload
        })
        if (createNote.data.id) {
          // eslint-disable-next-line no-unused-vars
          const updateStatusNote = await InventoryService.updateStatusWithdrawNotes({
            id: createNote.data.id,
            status: 'approve'
          }, {
            warehouse: this.warehouseSelected,
            ...payload
          })
          await this.updateProductList(index, skuIndex)
          this.setSnackbar({
            value: true,
            message: 'ลดสต็อกสำเร็จ',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('deductStock', error)
        const errorMessage = error.message.split(' ').slice(2).join(' ')

        this.setSnackbar({
          value: true,
          message: `ลดสต็อกไม่สำเร็จ -> ${errorMessage}`,
          type: 'error'
        })
      } finally {
        this.loadingPage = false
      }
    },
    async updateProductList (index, skuIndex) {
      const productId = this.products[index].id
      const { data } = await productService.getProductById(productId)
      for (let i = 0; i < data.skus.length; i++) {
        const sku = data.skus[i]
        sku.operation = 'Add'
        sku.qtyInput = 0
      }
      this.products.splice(index, 1, data)
      this.products[index].skus.splice(skuIndex, 1, {
        ...this.products[index].skus[skuIndex],
        qtyInput: 0
      })

      return data
    },
    searchProduct () {
      this.isLoadComplete = false
      this.query.page = 0
      this.getting = false
      this.products = []

      this.$router.push({
        name: 'ProductCostReport',
        query: {
          search: this.query.search || '',
          tags: this.query.tags || [],
          tagOperation: this.query.tagOperation,
          brand: this.query.brand,
          collection: this.query.collection,
          warehouse: this.warehouseSelected.code
        }
      }).catch((error) => {
        console.error(error)
      })

      this.initialInventory()
    },
    async fetchProducts () {
      this.getting = true
      if (this.query.page < this.totalPage) {
        this.query.page++
        try {
          const { data } = await productService.getProducts({
            page: this.query.page,
            limit: this.query.limit,
            search: this.query.search,
            tags: this.query.tags,
            tagOperation: this.query.tagOperation,
            brand: this.query.brand,
            collection: this.query.collection
          })
          this.totalPage = data.pages
          this.totalProduct = data.total
          this.products.push(...data.results)
        } catch (error) {
          console.error('fetchProducts', error)
          this.setErrorPage(error.code)
        }
      }
      this.isLoadComplete = this.query.page >= this.totalPage
      this.getting = false
    },
    handleScroll (event) {
      const e = event.target.scrollingElement
      const scrollY = e.scrollHeight - e.clientHeight - 1000
      if (e.scrollTop >= scrollY && !this.getting && !this.searchTimeout) {
        this.fetchProducts()
      }
    },
    capitalize (str) {
      return str.replace(/\b\w/g, (c) => c.toUpperCase())
    },
    download (url, fileName) {
      let tmpFilename = fileName
      if (!fileName) {
        tmpFilename = this.$dayjs().format('YYYYMMDDHHmmss')
      }
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = tmpFilename
      downloadLink.click()
    },
    async confirmExport () {
      try {
        this.loading = true

        const { data } = await productService.exportProductStock({
          search: this.query.search,
          tags: this.query.tags,
          tagOperation: this.query.tagOperation,
          brand: this.query.brand,
          collection: this.query.collection,
          warehouseSelected: this.warehouseSelected.id
        })

        if (data) {
          this.download(data)
        }
      } catch (error) {
        console.error('fetchProductsToExport', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
