<template>
  <v-row
    no-gutters>
    <v-col
      cols="4"
      sm="2"
      md="2"
      lg="2"
      xl="1">
      <gw-product-image
        :style="isMobile ? 'width: 30px' : 'width: 100px'"
        :src="product.photoUrls[0]"
        class="cursor-pointer"
        @click="previewImg(product.photoUrls[0])" />
    </v-col>
    <v-col
      cols="8"
      sm="10"
      md="10"
      lg="10"
      xl="11"
      class="pl-2">
      <v-row
        no-gutters>
        <v-col
          class="pl-2"
          cols="12">
          <h4 class="product-name subtitle-1 font-weight-bold">
            ชื่อสินค้า : {{ product.name }}
          </h4>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4">
              <p class="mb-0 text-body-2">
                <span class="text-bold">รุ่นสินค้า :</span> {{ product.model }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4">
              <p class="mb-0 text-body-2">
                <span class="text-bold">หมวดหมู่สินค้า (Website) :</span> {{ mapCategories(product.categories) }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4">
              <p class="mb-0 text-body-2">
                <span class="text-bold">หมวดหมู่สินค้า (Report) :</span>
                {{ product.productPrototype ? product.productPrototype.category : 'ไม่ระบุ' }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4">
              <p class="mb-0 text-body-2">
                <span class="text-bold">แบรนด์ :</span> {{ product.brand }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4">
              <p class="mb-0 text-body-2">
                <span class="text-bold">แท็ก </span>:
                <v-chip
                  v-for="(tag, i) in product.tags"
                  :key="`${product.id}-tag-${tag}-${i}`"
                  class="ma-1"
                  text-color="white"
                  color="#c379aa"
                  small>
                  {{ tag.name || tag }}
                </v-chip>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMobile"
        class="product-skus mt-1"
        no-gutters>
        <v-col cols="12">
          <v-divider class="mt-2"></v-divider>
          <v-simple-table>
            <thead>
              <tr>
                <th
                  v-for="(haedSku, i) in skusDetails"
                  :key="`skusDetails-${i}`"
                  :class="haedSku.cssClass">
                  {{ haedSku.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sku, i) in product.skus"
                :key="`skus-${i}`">
                <td>
                  {{ sku.code }}
                </td>
                <td align="center">
                  <span class="capitalize">
                    {{ sku.color.name }} -
                  </span>
                  {{ sku.size.name }}
                </td>
                <td align="center">
                  {{ sku.price | showFullPriceFormat() }}
                </td>
                <td align="center">
                  {{ (sku.price / product.productPrototype.margin) | showFullPriceFormat() }}
                </td>
                <td align="center">
                  <span v-if="stockReady">
                    {{ getQtySkuByWarehouse(sku, warehouse) | showNumberFormat() }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td align="center">
                  {{ (sku.price / product.productPrototype.margin) * getQtySkuByWarehouse(sku, warehouse) | showFullPriceFormat() }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="isMobile"
      cols="12">
      <v-divider class="mt-2"></v-divider>
      <v-row
        class="product-skus mt-1"
        no-gutters>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th
                  v-for="(haedSku, i) in skusDetails"
                  :key="`skusDetails-${i}`"
                  :class="haedSku.cssClass">
                  {{ haedSku.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sku, i) in product.skus"
                :key="`skus-${i}`">
                <td>
                  {{ sku.code }}
                </td>
                <td align="center">
                  <span class="capitalize">
                    {{ sku.color.name }} -
                  </span>
                  {{ sku.size.name }}
                </td>
                <td align="center">
                  {{ sku.price | showFullPriceFormat() }}
                </td>
                <td align="center">
                  {{ (sku.price / product.productPrototype.margin) | showFullPriceFormat() }}
                </td>
                <td align="center">
                  <span v-if="stockReady">
                    {{ getQtySkuByWarehouse(sku, warehouse) | showNumberFormat() }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td align="center">
                  {{ (sku.price / product.productPrototype.margin) * getQtySkuByWarehouse(sku, warehouse) | showFullPriceFormat() }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getAuthDecode } from '@/assets/js/Authentication'
import { mapActions } from 'vuex'
import SizeValues from '@/assets/js/SizeValues'

export default {
  props: {
    productId: {
      type: String,
      required: true,
      default: ''
    },
    productName: {
      type: String,
      required: true,
      default: ''
    },
    model: {
      type: String,
      required: true,
      default: ''
    },
    brand: {
      type: String,
      required: true,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: ''
    },
    skus: {
      type: Array,
      required: true,
      default: () => []
    },
    categories: {
      type: Array,
      required: true,
      default: () => []
    },
    tags: {
      type: Array,
      required: true,
      default: () => []
    },
    photoUrls: {
      type: Array,
      required: true,
      default: () => []
    },
    warehouseId: {
      type: Number,
      required: true,
      default: 0
    },
    warehouseName: {
      type: String,
      required: true,
      default: 'Main'
    },
    productPrototype: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      skusDetails: [
        { label: 'code', cssClass: 'text-left' },
        { label: 'Option', cssClass: 'text-center' },
        { label: 'Price ( ราคาขาย )', cssClass: 'text-center' },
        { label: 'Cost ( ต้นทุน * )', cssClass: 'text-center' },
        { label: 'Stock ( สต็อก )', cssClass: 'text-center' },
        { label: 'Stock Value', cssClass: 'text-center' }
      ],
      stockReady: true
    }
  },
  computed: {
    product () {
      return {
        id: this.productId,
        name: this.productName,
        model: this.model,
        brand: this.brand,
        variant: this.variant,
        skus: this.sortSize(this.skus),
        categories: this.categories,
        tags: this.tags,
        photoUrls: this.photoUrls,
        productPrototype: this.productPrototype
      }
    },
    warehouse () {
      this.reloadStock()
      return {
        id: this.warehouseId,
        name: this.warehouseName
      }
    },
    disabledButton () {
      const { role } = getAuthDecode()
      return this.warehouse.id === 0 || !(role === 'management' || role === 'developer')
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    previewImg (img) {
      this.$emit('preview:image', img)
    },
    reloadStock () {
      this.stockReady = false
      setTimeout(() => {
        this.stockReady = true
      }, 1)
    },
    checkReserveSource (sku, warehouseId) {
      this.$router.push({
        name: 'ReserveSource',
        params: { skuId: sku.id },
        query: { warehouseId }
      })
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    },
    mapCategories (data) {
      return data.map((r) => r).join(', ')
    },
    getLastNumber (num = 0) {
      const number = num.toString()
      return number[number.length - 1]
    },
    getQtySkuByWarehouse (sku) {
      const stock = sku.stock.find((r) => r.warehouse.id === this.warehouse.id)
      const onHandQty = stock && stock.onHandQty ? stock.onHandQty : 0
      return onHandQty
    },
    getReservedQtySkuByWarehouse (sku, warehouse) {
      const stock = sku.stock.find((r) => r.warehouse.id === warehouse.id)
      const onReservedQty = stock && stock.onReservedQty ? stock.onReservedQty : 0
      return onReservedQty
    }
  }
}
</script>

<style scoped>
img.img-lzd {
  display: block;
}
.skus-table th {
  text-transform: capitalize;
}
.stock-table {
  border: 1px solid  #858585;
  margin: -1px;
}
.product-image .main-image {
  display: block;
  margin: auto;
}
h4.product-name {
  margin-bottom: .5rem;
}
</style>

<style lang='scss'>
.stock-adjust {
  display: flex;
  justify-content: center;
}
.select-stock-adjust-operation {
  min-width: 75px;
  max-width: 75px;
  width: 75px;
  font-size: 11px;
  input {
    display: none;
  }
}
.input-stock-adjust-operation {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.stock-adjust {
  .v-input__icon {
    width: 10px;
    min-width: unset;
  }
  .v-input__icon .mdi:before {
    font-size: 18px;
  }
}
.reserve-text {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
